@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');


*,
:after,
:before {
  box-sizing: border-box;
}

html {
  text-align: right;
  direction: rtl;
  scroll-behavior: smooth;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

input, button {
  font-family: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; 
}

a {
  text-decoration: none;
  color: inherit;
}

.link {
  text-decoration: underline;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes fadeUp {
	0% {
		opacity: 0;
    transform: translateY(10px);
	}
	80% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
    transform: translateY(0px);
	}
}

@-webkit-keyframes fadeUp {
	0% {
		opacity: 0;
    transform: translateY(10px);
	}
	80% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
    transform: translateY(0px);
	}
}

  /* Chrome, Safari, Opera */
  @-webkit-keyframes myfirst {
    from {opacity: 0.2;}
    to {opacity: 1;}
  }

  /* Standard syntax */
  @keyframes myfirst {
    from {opacity: 0.2;}
    to {opacity: 1;}
  }

  /* Define the spin animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Apply the animation to a loader */
.loader {
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite; /* Apply the animation */
}

.revert {
  filter:invert(1);
}

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  transition: top 0.3s;
  z-index: 1000;
}

.navbar-show {
  top: 0;
}

.navbar-hide {
  top: -64px; /* Adjust this value based on your navbar height */
}